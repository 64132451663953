@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400&display=swap');

.book-font-family {
    font-family: "Dongle", sans-serif;
    background-color: #121926 !important;
}

.shine {
    margin: 0 auto;
    /* margin-bottom: 1em; */
    font-weight: bold;
}

.shine {
    background-image: -moz-linear-gradient(
            135deg,
            transparent 0 48%,
            hsla(0, 0%, 100%, 0.8) 50%,
            transparent 52% 100%
        ),
        -moz-linear-gradient(45deg, #b6a571, #cdb373, #e7da9a);
    background-image: linear-gradient(
            -45deg,
            transparent 48%,
            hsla(0, 0%, 100%, 0.8) 50%,
            transparent 52% 100%
        ),
        linear-gradient(45deg, #b6a571, #cdb373, #e7da9a);
}

.shine {
    color: transparent;    
    background-size: 200% 1600%, 100% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 3.5s linear infinite;
    -moz-animation: shine 3.5s linear infinite;
    animation: shine 3.5s linear infinite;
}

@keyframes shine {
    0%,
    10% {
        background-position: bottom;
    }
    20% {
        background-position: bottom;
    }
    90% {
        background-position: top;
    }
    100% {
        background-position: top;
    }
}


.custom-title{
    margin-bottom: 0px !important;
    padding-top: 40px;
    padding-left: 40px;
    font-size: 20px;
}

.custom-desc{
    font-weight: 100 !important;
    padding-top: 40px;
    padding-left: 40px;
    font-size: 29px !important;
}
.parentContainer{
    position: relative;
}

.page-controls {
    position: absolute;
    bottom: 5%;
    left: 40%;
    background: white;
    opacity: 0;
    transition: opacity ease-in-out .2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);;
    border-radius: 4px;
}

.parentContainer:hover .page-controls{
    opacity: 1;
}

.parentContainer:hover .page-controls > button{
    cursor:pointer !important;
}

.page-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: .8em;
    border-radius: 4px;
}

@media( width<= 600px ){
    .custom-title{
        padding-top: 20px;
        padding-left: 18px;
        padding-right: 10px;
        line-height: 10px;
    } 
    .shine{
        font-size:30px !important;
        line-height: 20px !important;
    }  
    .custom-desc{
        padding: 15px;
        padding-top: 10px;
        font-size: 28px !important;
        line-height: 25px !important;
        font-weight: 100 !important;
    }
    .page-controls {
        position: absolute;
        bottom: 5%;
        left: 50%;
        background: white;
        opacity: 1;
        transform: translate(-50%);
        transition: opacity ease-in-out .2s;
        box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);;
        border-radius: 4px;
    }
    /* .parentContainer1{
        width: 400px !important;
        height: 400px !important;
    } */
}


@media( 600px < width<= 1000px ){
    .custom-title{
        padding: 10px;
        padding-top: 40px;
        padding-left: 10px;
        line-height: 10px;
    } 
    .shine{
        font-size:30px !important;
        line-height: 20px !important;
    }  
    .custom-desc{
        padding: 15px;
        padding-top: 10px;
        font-size: 28px !important;
        line-height: 25px !important;
        font-weight: 100 !important;
    }
}

@media (min-width: 850px){
    .carousel-width{
        width: 1000px !important;
    }
}
/* 

@media (min-width: 0px){
    .flexible-card{
        max-width: 120px !important;
        max-height: 120px !important;
    }
}

@media (min-width: 400px){
    .flexible-card{
        max-width: 350px !important;
        max-height: 200px !important;
    }
}

@media (min-width: 612px){
    .flexible-card{
        max-width: 400px !important;
        max-height: 250px !important;
    }
}


@media (min-width: 720px){
    .flexible-card{
        max-width: 500px !important;
        max-height: 250px !important;
    }
}


@media (min-width: 790px){
    .flexible-card{
        max-width: 550px !important;
        max-height: 300px !important;
    }
}


@media (min-width: 880px){
    .flexible-card{
        max-width: 650px !important;
        max-height: 300px !important;
    }
}


@media (min-width: 990px){
    .flexible-card{
        max-width: 750px !important;
        max-height: 400px !important;
    }
}

@media (min-width: 1085px){
    .flexible-card{
        max-width: 850px !important;
        max-height: 500px !important;
    }
}

@media (min-width: 1350px){
    .flexible-card{
        max-width: 1250px !important;
        max-height: 600px !important;
    }
}
 */
