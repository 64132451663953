.glass-morphism {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 0;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.63), transparent);
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}
.align-items-end {
    -ms-flex-align: end !important;
    align-items: end !important;
}
.align-items-start {
    -ms-flex-align: start !important;
    align-items: start !important;
}
.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: start !important;
}
.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    padding-bottom: 0px;
}

.swiper-slide-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ant-card-body {
    padding: 0px !important;
}
.ant-card-bordered {
    border: 0px !important;
}
.ant-card-meta-title {
    color: #fff !important;
    font-weight: 900 !important;
}

.container-1 {
    background-color: "#ffffff";
    padding: 0px 40px 20px 40px;
    border-radius: 10px;
}

.item {
    /* position: relative;
  display: block;
  flex: 1 1 0px; */
    transition: transform 500ms;
}
.item:hover {
    transform: scale(1.5) !important;
    z-index: 1 !important;
}

@media (max-width: 500px) {

    .card-item{
        width: 100px !important;
        height: 100px !important;
    }
    .custom-card-img-css {
        width: 303px !important;
        height: 239px !important;
        border-radius: 20px !important;
    }
    .container-1 {
        padding: 0px 5px 5px 5px !important;
    }
}

@media (max-width: 300px) {
    .custom-card-img-css {
        width: 253px !important;
        height: 220px !important;
        border-radius: 20px !important;
    }
}
