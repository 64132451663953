.mySvg::before {
    position: relative;
    background-repeat: no-repeat;
    content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='200' height='10' preserveAspectRatio='none' viewBox='0 0 200 10'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1390%26quot%3b)' fill='none'%3e%3cpath d='M21 5L157 4' stroke-width='6' stroke='url(%23SvgjsLinearGradient1391)' stroke-linecap='round' class='Left'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1390'%3e%3crect width='200' height='10' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='100%25' y1='0%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1391'%3e%3cstop stop-color='rgba(248%2c 117%2c 44%2c 0)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(248%2c 117%2c 44%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e")
}


.container-s {
    position: relative;
    text-align: center;
    color: white;
    flex-direction: row;
    display: flex;
  }
  /* Centered text */
  .centered {
    position: static;
    left: 2%;
    transform: translate(-10%, 25%);
  }

  @media (max-width: 500px) {
    .ms2{
        padding-left: 20px !important;
    }
}

