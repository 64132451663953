.container-s {
    position: relative;
    text-align: center;
    color: white;
    flex-direction: row;
    display: flex;
}

/* Bottom left text */
.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}

/* Top left text */
.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}

/* Top right text */
.top-right {
    position: absolute;
    top: 8px;
    right: 16px;
}

/* Bottom right text */
.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

/* Centered text */
.centered {
    position: static;
    left: 2%;
    transform: translate(10%, 25%);
}

.meta-data{
  font-weight: bold;
  padding-right: 5px;
}

.img-text-wrapper {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    /* height: 400px !important; */
    text-align: left !important;
    width: 90%;
    height: inherit;
}

@media (width >= 1200px) {
    .img-text-wrapper {
        position: fixed;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        /* height: 400px !important; */
        text-align: left !important;
        width: 90%;
        height: inherit;
    }

    .logo-wrapper img {
        position: absolute;
        width: 100%;
        margin-bottom: 20px;
        height: inherit;
    }
}

.subtitle {
    font-family: "Open Sans Condensed", sans-serif !important;
    padding: 20px;
    padding-top: 150px;
    font-size: 20px;
    display: flex;
    width: 400px !important;
    position: absolute;
    transition: 1s ease-in-out;
    color: white !important;
    height: 440px !important;
}

.img-text-wrapper:hover .subtitle {
    color: teal;
    font-weight: 400;
}

.logo-wrapper img {
    width: 100%;
    margin-bottom: 20px;
    height: inherit;
}


.title.ellipsize {
    height: auto;
    -webkit-line-clamp: 2;
}

.title {
    text-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    font-size: 35px;
    color: #fff;
    font-weight: 600;
}

.title1 {
    text-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    font-size: 28px;
    color: #fff;
    font-weight: 600;
}

.title1.ellipsize {
    height: auto;
    -webkit-line-clamp: 2;
}

.ellipsize {
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
}

.toptitle {
    color: var(--TEXT_COLOR_L2);
    font-size: 14px;
    display: inline-block;
    vertical-align: bottom;
    font-weight: 400;
}

.description {
    font-size: 16px;
    padding-top: 10px;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    line-height: 28px;
}

.content-holder .description {
    font-size: 14px;
    color: var(--TEXT_COLOR_L1);
    font-weight: normal;
}

.subtitle{
  background: -webkit-gradient(linear, left top, right top, from(#030b17), to(rgba(0,0,0,0)));
  background: -webkit-linear-gradient(left, #030b17, rgba(0,0,0,0)) !important;
  background: -o-linear-gradient(left, #030b17, rgba(0,0,0,0)) !important; 
  background: linear-gradient(to right, #030b17, rgba(0,0,0,0))!important;
}

.swiper-size{
    width: 100%;
    height: 440px !important;
}


@media only screen and (max-width: 768px) {
    .screen-size1{
        font-size: 18px;
    }

    .screen-size2{
        font-size: 14px;
    }

    .screen-size3{
        font-size: 8px;
        padding-top: 5px;
        overflow-wrap: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        line-height: 24px;
    }

    .subtitle {
        font-family: "Open Sans Condensed", sans-serif !important;
        padding: 20px;
        padding-top: 60px !important;
        font-size: 20px;
        width: 300px !important;
        position: absolute;
        transition: 1s ease-in-out;
        color: white !important;
        height: 250px !important;
    }

    .swiper-size{
        width: 100%;
        height: 250px !important;
    }
    

    .toptitle {
        line-height: normal;
    }
    .toptitle {
        text-shadow: 0 2px 4px rgb(0 0 0 / 20);
    }

    .title1.ellipsize {
        height: auto;
        -webkit-line-clamp: 1;
    }
}

